import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";
export const ShipingrateAPi = (token, formdata, callback) => {
    let data = JSON.stringify(formdata);
  
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}shipping-rate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
  
    axios
      .request(config)
      .then((response) => {
        // console.log(response,"shippinggggggg");
        callback(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  export const TrackOrderAPi = (token, formdata, callback) => {
    let data = JSON.stringify(formdata);
  
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}order-track`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
  
    axios
      .request(config)
      .then((response) => {
        callback(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };




  