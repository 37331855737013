import React, { useEffect,useState } from 'react'
import Topbar from '../Sections/Topbar'
import Footer from '../Sections/Footer';
import Navbar from '../Sections/Navbar';
import "../Styles/AboutUs.css"

const AboutUs = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  return (
    <>
      <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
        <Topbar />
        <Navbar />
      </div>
      <div className="container py-3">
        <h3 className='fw-bold d-flex justify-content-center'>About Us</h3>
        <h6 className="mt-5 mb-3">
          <b>Mission</b>
        </h6>
        <p className="p2-3 ">
          Our mission is to provide denim that surpasses customer expectations in
          quality, comfort, and style. We aim to offer a memorable shopping
          experience, fostering long-term relationships with our customers built on
          trust and satisfaction.
        </p>
        <h6 className="mb-3">
          <b>Vision</b>
        </h6>
        <p className="p2-3 ">
          We prioritize quality in every step of the manufacturing process, from
          fabric selection to final touches. Our denim collection features various
          styles, including classic, distressed, and clean-cut designs, catering to
          different tastes and body shapes. We continuously invest in research and
          development to stay ahead of trends, ensuring our customers always have
          access to the latest in denim fashion.
        </p>
        <h6 className="mb-3">
          <b>
            Our Strengths
            <h6 className="mt-2">At HASASI DENIM WEAR, we take pride in:</h6>
            <ul className="about-ul">
              <li>
                <p> Sourcing the finest raw materials for premium quality</p>
              </li>
              <li>
                <p>
                  {" "}
                  Offering a wide range of denim options tailored to various
                  preferences and body types
                </p>
              </li>
              <li>
                {" "}
                <p>Ensuring meticulous craftsmanship in every pair of jeans</p>
              </li>
              <li>
                {" "}
                <p>
                  Maintaining competitive pricing while delivering high-quality
                  products
                </p>
              </li>
              <li>
                {" "}
                <p>
                  Upholding a proven track record for on-time delivery of large orders
                  with short lead times
                </p>
              </li>
              <li>
                {" "}
                <p>Product Quality and Range</p>
              </li>
            </ul>
          </b>
        </h6>
        <p>
          We prioritize quality in every step of the manufacturing process, from
          fabric selection to final touches. Our denim collection features various
          styles, including classic, distressed, and clean-cut designs, catering to
          different tastes and body shapes. We continuously invest in research and
          development to stay ahead of trends, ensuring our customers always have
          access to the latest in denim fashion.
        </p>
        <h6 className="mb-3">
          <b>Customer Satisfaction</b>
        </h6>
        <p className="p2-1 ">
          Customer satisfaction is at the core of our philosophy. We go the extra mile
          to ensure every interaction, from inquiry to delivery, is seamless. Our
          dedicated customer service team is always ready to assist, ensuring that
          orders are processed efficiently, and products are delivered on time.
        </p>
        <h6 className="mb-3">
          <b>Conclusion</b>
        </h6>
        <p className="p2-3 ">
          At HASASI DENIM WEAR, we strive to create premium jeans that exude style,
          comfort, and durability. Our dedication to quality and customer service
          ensures we surpass customer expectations, fostering relationships built on
          trust and mutual growth.
        </p>
        <h6 className="mb-3">
          <b>
            Contact Us
            <h6 className="mt-2">HASASI DENIM WEAR</h6>
            <h6 className="mt-2">
              9th Floor, Unit No. 914, Wing C &amp; D, Shiv Solitaire, Station Road,
              Jogeshwari East, Mumbai - 400060
            </h6>
            <h6 className="mt-2">
              Mobile Number :<a href="tel:8779958981">8779958981</a>
            </h6>
          </b>
        </h6>
        <h6 className="mt-2">
          Email Id: :
          <a href="mailto:support@hasasidenim.com">support@hasasidenim.com</a>
        </h6>
      </div>
      <Footer />
    </>
  )
}

export default AboutUs