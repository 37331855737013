import { Offer_Details } from "../type"


const initialState = {
    offers: []
}

const offerReducer = (state = initialState, action) => {
    switch (action.type) {
        case Offer_Details:
            return {
                ...state,
                offers: action.payload
            }
        default: return state
    }
}

export default offerReducer;