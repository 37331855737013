import React,{useEffect,useState} from 'react'
import Topbar from '../Sections/Topbar'
import Navbar from '../Sections/Navbar'
import Footer from '../Sections/Footer'
import { useTranslation } from "react-i18next";

const TermsonUse = () => {
  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])
  return (
    <>
       <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
            <Topbar />
            <Navbar />
          </div>
      <div style={{borderTop:"2px solid #2e2e97"}}></div>

        <div className='container my-5'>
            <div>
                <h6 className='fw-bold'>{t('ABOUTtrms')}</h6>
                
                <p>{t('ABOUTfound')}</p>
                
                <h6 className='fw-bold mt-5'>{t('ABOUTORDER')}</h6>
                <p>{t('ABOUTCOsmetic')}</p>
                <h6 className='fw-bold'>{t('ABOUTDELIVERY')}</h6>
                <p>{t('ABOUTRAPID')}</p>
                <h6 className='fw-bold'>{t('ABOUTCUSTOMER')}</h6>
                <p>{t('ABOUTTELPHONE')}</p>
                <h6 className='fw-bold'>{t('ABOUTYNagative')}</h6>
                <p>
                  {t('ABOUTYAliwe')}
               </p>
                <h6 className='fw-bold'>{t('ABOUTTACKING')}</h6>
                <p>{t('ABOUTSERVICE')}</p>
            </div>
        </div>
       <Footer />
    </>
  )
}

export default TermsonUse;