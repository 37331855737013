import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";

export const AddWishlistApi = (
  token,
  formdata,
  updateProductPage,
  setUpdateProductPage,
  FetchWishlistApi,
  setUpdateProductSetWishLength
) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}add-to-wishlist`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (response.data.status === "failed") {
        ErrorToaster(response.data.message);
      } else {
        setUpdateProductPage(!updateProductPage);
        SuccessToaster(response.data.message);
        FetchWishlistApi(token, formdata, (callbackData) => {
          setUpdateProductSetWishLength(callbackData.length);
        });
      }
    })
    .catch((error) => {
      if (token) {
        ErrorToaster(error?.response?.data?.message);
      }
    });
};

export const RemoveWishlistApi = (
  token,
  fromdata,
  updateWishtPage,
  setUpdateWishPage
  // FetchWishlistApi, setWishLengthRedux
) => {
  let data = JSON.stringify(fromdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}remove-to-wishlist`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (response.data.status === "failed") {
        ErrorToaster(response.data.message);
      } else {
        setUpdateWishPage(!updateWishtPage);
        SuccessToaster(response.data.message);
        // if(response.data.message==="success"){
        //   FetchWishlistApi(token, fromdata, (callback)=>{
        //   // setWishLengthRedux(response?.data?.data?.length)
        //   console.log(callback)
        // }
        //   )
        // }
      }
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};

export const FetchWishlistApi = (token, formdata, thirdParam, callback) => {
  let data = JSON.stringify(formdata);
  let setLoading =
    typeof thirdParam === "function" && typeof callback === "function"
      ? thirdParam
      : null;
  let finalCallback =
    typeof thirdParam === "function" && typeof callback === "function"
      ? callback
      : thirdParam;

  if (setLoading) setLoading(true);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-wishlist-products`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      finalCallback(response.data);
      if (setLoading) setLoading(false);
    })
    .catch((error) => {
      if (error?.response?.data?.message === "Authorization Failed") {
        localStorage.removeItem("Relove_Token");
      }
      if (setLoading) setLoading(false);
      // if (token) {
      //   ErrorToaster(error?.response?.data?.message);
      // }
    });
};
