import React, { useState, useLayoutEffect } from "react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./../Styles/topsaleproduct.css";
import { FetchWishlistApi } from "../APIS/WishlistApis";
import { useTranslation } from "react-i18next";
import LoginModal from "../Modals/LoginModal";
import { setCartLength, setWishLength } from "../Redux/actions";

function AllProducts({
  allProducts,
  handleWisthlist,
  getSelectedLanguage,
  getUserAuthenticationRedux,
  updateProductPage,
  setWishLengthRedux,
}) {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [allWishtDate, setAllWishData] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);

  useLayoutEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    FetchWishlistApi(Relove_Token, formdata, (callbackData) => {
      setAllWishData(callbackData.data);
      setWishLengthRedux(callbackData);
    });
  }, [updateProductPage]);

  const nonEmptyProducts = allProducts.filter(item => item?.products.length > 0);

  return (
    <>

      <LoginModal
        setShowLoginModal={setShowLoginModal}
        showLoginModal={showLoginModal}
      />
      <div className="allproducts-product-main ">
        {nonEmptyProducts &&
          nonEmptyProducts?.map((item, i) => {
            const backgroundColor = i % 2 === 0 ? '#E4F1FF' : '#ffffff';
            return (
              <div key={i} style={{ backgroundColor }} className="py-3">
                <div className="container">
                  <h4 className="all-product-sub-heading">
                    {item?.collectionName}
                  </h4>
                  <ul className=" d-flex">
                    <>
                      {item?.products?.map((ele, i) => {
                        return (
                          <li key={i} className="mt-3">
                            <div className="allproducts-background-div text-center">
                              <h6
                                onClick={() => {
                                  let Relove_Token =
                                    localStorage.getItem("Relove_Token");

                                  if (!Relove_Token) {
                                    setShowLoginModal(true);
                                  } else {
                                    handleWisthlist(ele.id);
                                  }
                                }}
                                className="text-end"
                              >
                                {allWishtDate.findIndex(
                                  ({ product_id }) => +product_id === +ele.id
                                ) === -1 ? (
                                  <AiOutlineHeart />
                                ) : (
                                  <AiFillHeart color="black" />
                                )}
                              </h6>
                            </div>
                            <div onClick={() => navigation(`/product/${ele.id}`)}>
                              <img src={ele.featured_image} alt="" />
                              <div style={{ backgroundColor: "#ffffff", padding: '10px', borderRadius: "0px 0px 8px 8px" }}>
                                <h5
                                  className="text-start"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {ele?.name}
                                </h5>
                                <div className="d-flex justify-content-between align-items-center">
                                  <p>
                                    {t("₹")}{ele?.sale_price}{" "}
                                    <span className="discount-price">
                                      {t("₹")}({ele?.base_price})
                                    </span>
                                  </p>
                                </div>
                                <div className="add-to-cart-button mt-3">
                                  <button
                                    onClick={() => {
                                      navigation(`/product/${ele.id}`);
                                    }}
                                  >
                                    Shop Now
                                  </button>
                                </div>
                              </div>
                            </div>


                          </li>
                        );
                      })}
                    </>
                  </ul>
                </div>
              </div>
            );
          })}
      </div>

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setWishLengthRedux: (parameter) => {
      dispatch(setWishLength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllProducts);
