import { setSelectedLanguages } from "../Redux/actions";
import { connect } from "react-redux";

import "./../Styles/topbar.css";
import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";



function Topbar({ setSelectedLanguagesRedux, getSelectedLanguage, getOffers }) {

  const formattedOffers = getOffers?.map(offer => offer.name).join(' / ');
  return (
    <div className="marquee">
      {
        <span>
          {formattedOffers}
        </span>

      }
    </div>
    // <div className="topbar">
    //   <div className="container-fluid">
    //     <div className="row d-flex justify-content-end align-items-center">
    //       <div className="col-xl-4 col-lg-5 col-md-7 topbar_col">
    //         <div className="topbar-main-1">
    //           <FaPhoneAlt className="icn" />
    //           <p>+91 87799 58981</p>
    //         </div>
    //         <div className="topbar-main-1">
    //           <FaEnvelope className="icn" />
    //           <p>support@hasasidenim.com</p>
    //         </div>
    //       </div>

    //     </div>
    //   </div>
    // </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
    getOffers: state.offer.offers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedLanguagesRedux: (parameter) => {
      dispatch(setSelectedLanguages(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
