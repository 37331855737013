import React, { useState, useEffect } from "react";
import { getParentCategory } from "../APIS/apis";
import { useNavigate } from "react-router-dom";
import "./../Styles/parentcategory.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ParentCategory() {
  let language = localStorage.getItem("language");
  const navigation = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    getParentCategory(language, (response) => {
      setData(response);
    });
  }, [language]);

  const slickSettings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {
        <div className="parentcategory" style={{ backgroundColor: "#E4F1FF" }}>
          <div className="container">
            <div className="row py-sm-5 py-4 justify-content-center circle_row">
              <h3>CATEGORIES</h3>
              <div className="d-flex align-items-center justify-content-lg-center parent-category-row py-2">
                {data?.map((item, i) => {
                  return (
                    <div
                      onClick={() =>
                        navigation(`/category/${item?.id}/${item?.slug}`)
                      }
                      key={i}
                      className="parentcategory-main circle"
                    >
                      <div className="parent-category-img-div d-flex align-items-center justify-content-center">
                        <img src={item?.image} alt="" className="parent-category-img" />
                      </div>
                      <p>{item?.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default ParentCategory;
