import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorToaster } from "../Toaster";
import { registerApi } from "../APIS/AuthApis";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useTranslation } from "react-i18next";

import { AiOutlineGoogle } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import "./../Styles/signup.css";

let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
function Signup() {
  const navigation = useNavigate();

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [userNameError, setUserNameError] = useState(false);
  const [emailError, setEmailrror] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const { t } = useTranslation();

  const handleSignup = (e) => {
    e.preventDefault();
    if (userName === "") {
      ErrorToaster("Please fill the user name");
      setUserNameError("Please fill the user name");
    } else if (email === "") {
      ErrorToaster("Please fill the email");
      setEmailrror("Please fill the email");
    } else if (password === "" || cpassword === "") {
      ErrorToaster("Please fill the password");
      setPasswordError("Please fill the password");
    } else if (emailreg.test(email) === false) {
      ErrorToaster("Email format is not correct it contain @ & .com");
      setEmailrror("Email format is not correct it contain @ & .com");
    } else if (passReg.test(password) === false) {
      ErrorToaster(
        "Password with minimum 8 characters include atleast 1 uppercase, 1 special character, and alphanumeric characters"
      );
      setPasswordError(
        "Password with minimum 8 characters include atleast 1 uppercase, 1 special character, and alphanumeric characters"
      );
    } else if (password !== cpassword) {
      ErrorToaster("Confirm password and password does not match");
      setPasswordError(
        "Password with min 8 characters include atleast 1 uppercase, 1 special character, and alphanumeric characters"
      );
    } else {
      let formData = {
        "name": userName,
        "email": email,
        "password": password,
        "confirm_password": cpassword,
        "token": "user-a576-4210-94d7-d70d7a6a124b"
      }
      registerApi(formData, navigation);
    }
  };

  return (
    <>
      <div className="container-fluid m-0 p-0">
        <div className="row m-0">
          <div className="col-lg-6 d-none d-lg-flex singup-left p-0">
            <img src="\img\singupimage\signup.jpg" alt="image" />
          </div>

          <div className="col-lg-6 singup-right d-flex justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <h3>Create An Account</h3>
              <p className="enter-details">Enter Your Details Below</p>
              <form onSubmit={(e) => handleSignup(e)}>
                <input
                  style={{
                    borderColor: userNameError ? "red" : "",
                    color: userNameError ? "red" : "",
                  }}
                  type="text"
                  placeholder={t("USERNAME")}
                  className="mt-4 "
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                    setUserNameError(false);
                  }}
                />
                {userNameError ? (
                  <p className="m-0 text-danger">{userNameError}</p>
                ) : null}

                <input
                  style={{
                    borderColor: emailError ? "red" : "",
                    color: emailError ? "red" : "",
                  }}
                  type="text"
                  placeholder="Email"
                  className="mt-4"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailrror(false);
                  }}
                />
                {emailError ? (
                  <p className="m-0 text-danger">{emailError}</p>
                ) : null}
                <div className="d-flex align-items-center position-relative ">
                  <input
                    style={{
                      borderColor: passwordError ? "red" : "",
                      color: passwordError ? "red" : "",
                    }}
                    type={showPassword ? "text" : "password"}
                    placeholder={t("Password")}
                    className="mt-4"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError(false);
                    }}
                  />
                  <div>
                    {showPassword ? (
                      <AiOutlineEyeInvisible
                        onClick={() => setShowPassword(!showPassword)}
                        className="eye-icond"
                      />
                    ) : (
                      <AiOutlineEye
                        onClick={() => setShowPassword(!showPassword)}
                        className="eye-icond"
                      />
                    )}
                  </div>
                </div>
                {passwordError ? (
                  <p className="m-0 text-danger">{passwordError}</p>
                ) : null}

                <div>
                  <div className="d-flex align-items-center position-relative ">
                    <input
                      style={{
                        borderColor: passwordError ? "red" : "",
                        color: passwordError ? "red" : "",
                      }}
                      type={showCPassword ? "text" : "password"}
                      placeholder={t("ConfirmPassword")}
                      className="mt-4"
                      value={cpassword}
                      onChange={(e) => {
                        setCPassword(e.target.value);
                        setPasswordError(false);
                      }}
                    />
                    <div>
                      {showCPassword ? (
                        <AiOutlineEyeInvisible
                          onClick={() => setShowCPassword(!showCPassword)}
                          className="eye-icon"
                        />
                      ) : (
                        <AiOutlineEye
                          onClick={() => setShowCPassword(!showCPassword)}
                          className="eye-icon"
                        />
                      )}
                    </div>
                  </div>
                  {passwordError ? (
                    <p className="m-0 text-danger">{passwordError}</p>
                  ) : null}

                  <p className="use mt-1">{t("UseSingmore")}</p>
                </div>

                <button
                  type="submit"
                  className="d-flex justify-content-center align-items-center w-100 text-dark mt-3 create-btn"
                >
                  {t("CreateSignAccount")}
                </button>
              </form>
              {/* 
              <div className="bottom-paragraph mt-4">
                <p className="connect">Connect With Us</p>
              </div>
              <button className=" mt-4 bg-dark w-100 text-white bottom-button d-flex align-items-center justify-content-center ">
                <AiOutlineGoogle />
                Continue Google
              </button>
              <button className=" mt-2 bg-dark w-100 text-white bottom-button d-flex align-items-center justify-content-center ">
                <FaFacebookF />
                Continue Facebook
              </button>

              <button className=" mt-2 bg-dark w-100 text-white bottom-button d-flex align-items-center justify-content-center ">
                <i className="fa fa-user"></i>
                Continue Guest
              </button> */}

              <h6 className="text-center mt-3 signup-already-account">
                Already Have An Account?
                <span onClick={() => navigation("/login")}>
                  {" "}
                  {t("SignClick")}
                </span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
