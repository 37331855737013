import React from "react";
import "./../Styles/cards.css";

function Cards({ otherBanner }) {

  const style1 = {
    backgroundImage: otherBanner && otherBanner.length > 0 && `url(${otherBanner[0]?.bannerImage})`,
  }

  const style2 = {
    backgroundImage: otherBanner && otherBanner.length > 0 && `url(${otherBanner[1]?.bannerImage})`,
  }

  return (
    <>
      <div className="container mb-5 p-md-0">
        <div className="row ">
          <div className="col-md-6 ">
            <div className="card_1" style={style1}>
              <div className="card_1-div">
                <h2 style={{ color: "#2e2e97", fontWeight: "900" }}>
                  {otherBanner && otherBanner.length > 0 && otherBanner[0].text_one}
                </h2>
                <h5>{otherBanner && otherBanner.length > 0 && otherBanner[0].text_two}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="card_2" style={style2}>
              <div className="card_2-div">
                <h2>
                  {otherBanner && otherBanner.length > 1 && otherBanner[1].text_one}
                </h2>
                <h5>{otherBanner && otherBanner.length > 1 && otherBanner[1].text_two}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
