import {createStore, combineReducers , applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import allProductsReducers from './reducers/allProductsReducers';
import userAuthenticationReducer from './reducers/userAuthenticationReducer';
import setSelectedLanguageReducer from './reducers/setSelectedLanguageReducer'
import cartLengthReducer from './reducers/cartLengthReducer';
import userDetailsReducer from './reducers/UserDetails';
import wishLengthReducer from './reducers/wishLengthReducer';
import SingleAddressReducer from "./reducers/SingleAddress"
import userDeaitlsReducer from './reducers/UserDeaitals';
import offerReducer from './reducers/offersReducer';


export const rootReducer =  combineReducers({
allProducts:allProductsReducers,
userAuthentication:userAuthenticationReducer,
language:setSelectedLanguageReducer,
length:cartLengthReducer,
user:userDetailsReducer,
wishlength:wishLengthReducer,
SingleAddress:SingleAddressReducer,
userid:userDeaitlsReducer,
offer:offerReducer,
});
  
// Passing burgerReducer to createStore
const store=createStore(rootReducer, applyMiddleware(thunk));
  
export default store;