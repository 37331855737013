import React, {useState, useEffect } from 'react';
import {NotificationApi} from "../APIS/apis";
const style={
    backgroundColor:"rgb(235 237 243)",
}
const Notification = () => {
  const [notification,setNotification]=useState([])
  useEffect(()=>{
    let Relove_Token = localStorage.getItem("Relove_Token");

    let Formdata={
      page:1,
    }
     
    // console.log(Formdata,"999999")
    NotificationApi( Relove_Token ,Formdata,(callback)=>{
      console.log(callback,"callback")
      setNotification(callback.notifications)
    })
  },[])
  console.log(notification)
  return (
    <div>
        <div>
        <div className="alert notification" style={style} role="alert">
          This is a secondary alert—check it out!
         </div>
        </div>
    </div>
  )
}

export default Notification