import React from "react";
import "./../Styles/fashionsale.css";

function FashionSale({ offerBanner }) {
  return (
    <div className="fashiondale py-5">
      <div className="container">
        <div className="row fashion_row">
          {
            offerBanner && offerBanner.length > 0 && <div className="col-md-6  fashionsale-background">
              <img src={offerBanner && offerBanner.length > 0 && offerBanner[0].bannerImage} alt="" />
            </div>
          }
          {
            offerBanner && offerBanner.length > 1 && <div className="mt-3 mt-md-0 col-md-6 fashionsale-background">
              <img src={offerBanner && offerBanner.length > 0 && offerBanner[1].bannerImage} alt="" />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default FashionSale;
