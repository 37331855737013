import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { addAddressApi } from "../APIS/AuthApis";
import { FetchAddressApi } from "../APIS/apis";
import { IoMdClose } from "react-icons/io";
import { ErrorToaster } from "../Toaster";
import "../Styles/NewAddressModal.css"
import { BsPersonAdd } from "react-icons/bs";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0.5px solid  #2e2e97",
    maxHeight: '80vh',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
};

const hideScrollbarStyle = {
    '&::-webkit-scrollbar': {
        display: 'none',
    },
};

const combinedStyle = {
    ...modalStyle,
    ...hideScrollbarStyle,
};

function NewAddressModal({ open, setOpen, setAddress }) {
    const [input, setInput] = useState({
        name: "",
        city: "",
        state: "",
        street: "",
        landmark: "",
        code: "",
        mobile: "",
        phone_code: "+91",
        phone_country: "in",
        address_type: "Home",
        default: 1,
    });

    const handleinput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInput({ ...input, [name]: value });
    };

    const handleSubmit = (e) => {
        let Relove_Token = localStorage.getItem("Relove_Token");
        let formdata = {
            language: "en",
        };
        e.preventDefault();
        if (input?.code?.length !== 6) {
            ErrorToaster("Pincode should be of 6 digits")
            return
        }
        else if (input?.mobile?.length !== 10) {
            ErrorToaster("Mobile Number length should be of 10 digits")
            return
        }
        else {
            addAddressApi(Relove_Token, input, FetchAddressApi, formdata, (callback) => {
                setAddress(callback?.data);
            });
            setOpen(false)
        }

    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={combinedStyle} className="modalpopup modal-content">
                <Typography id="modal-modal-description" sx={{ mt: 2 }}  >
                    <div className="d-flex justify-content-between" style={{ padding: "10px 20px" }}>
                        <h5 style={{ marginBottom: "20px" }}>Add New Address</h5>
                        <IoMdClose onClick={() => setOpen(false)} fontSize={25} style={{ cursor: "pointer" }} />
                    </div>
                    <form className="new-address-form" onSubmit={handleSubmit}>
                        <fieldset>
                            <legend>Contact Details</legend>
                            <div className="form-parts">
                                <label for="name">Full Name*</label>
                                <input type="text" id="name" name="name" value={input.name} onChange={handleinput} required />

                                <label for="mobile">Mobile No*</label>
                                <input type="number" id="mobile" name="mobile" value={input.mobile} onChange={handleinput} required />
                            </div>

                            <legend>Address</legend>
                            <div className="form-parts">
                                <label for="code">Pin Code*</label>
                                <input type="number" id="code" name="code" value={input.code} onChange={handleinput} required />

                                <label for="landmark">Address (House No, Building, Street, Area)*</label>
                                <input type="text" id="landmark" name="landmark" value={input.landmark} onChange={handleinput} required />

                                <label for="street">Locality/Town*</label>
                                <input type="text" id="street" name="street" value={input.street} onChange={handleinput} required />

                                <label for="city">City*</label>
                                <input type="text" id="city" name="city" value={input.city} onChange={handleinput} required />

                                <label for="state">State*</label>
                                <input type="text" id="state" name="state" value={input.state} onChange={handleinput} required />
                            </div>

                            <legend>Save Address As</legend>
                            <div className="form-parts d-flex align-items-center">
                                <div className="d-flex align-items-center me-2">
                                    <input type="radio" id="home" name="addressType" value={"Home"} onClick={handleinput} checked={input.address_type === "Home" ? true : false} />
                                    <label for="home">Home</label>
                                </div>

                                <div className="d-flex align-items-center">
                                    <input type="radio" id="work" name="addressType" onClick={handleinput} value={"Office"} checked={input.address_type === "Office" ? true : false} />
                                    <label for="work">Office</label>
                                </div>
                            </div>
                            <div className="form-parts d-flex justify-content-end">
                                <button type="submit">Add Address</button>
                            </div>
                        </fieldset>
                    </form>
                </Typography>
            </Box>
        </Modal>
    )
}

export default NewAddressModal
