import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllProductsHomeApi } from "../APIS/apis";
import "./../Styles/similierproduct.css";
import { useTranslation } from "react-i18next";

function SimilierProducts({ setUpdateProductPage, updateProductPage, productData }) {
  console.log("productData", productData)
  const navigation = useNavigate();
  const { t } = useTranslation();
  let language = localStorage.getItem("language")
  const [products, setProducts] = useState([]);

  return (
    <>
      {
        productData && productData?.length > 0 && <div className="similierproduct">
          <div className="container p-0 mt-3">
            <div className="similierproduct-main">
              <h3 className="m-0">{t('SimilierProducts')}</h3>
              <ul>
                {
                  productData[0].suggestedProduct.slice(0, 10).map((item, i) => {
                    return (
                      <li key={i} className="mt-3" onClick={() => {
                        navigation(`/product/${item.productId}`);
                        setUpdateProductPage(!updateProductPage)
                      }} >
                        <img src={item.featured_image} alt='similiarImage'></img>
                        <div style={{ padding: "10px" }}>
                          <h5 className="text-start" style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}>{item?.name}</h5>
                          <div className="d-flex justify-content-between align-items-center">
                            <p>
                              {t('₹')}{item?.sale_price} {" "}
                              <span className="discount-price">{t('₹')}({item?.base_price})</span>
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default SimilierProducts;
