import React, { useEffect, useState, useRef } from "react";
import { SlUser } from "react-icons/sl";
import { BsHeart } from "react-icons/bs";
import { BsBagCheck } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { setUserAuthentication } from "../Redux/actions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Badge from "@mui/material/Badge";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./../Styles/navbar.css";
import { Searchproduacts } from "../APIS/apis";
import { IoMenu } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";
import { BiCategory } from "react-icons/bi";
import LoginModal from "../Modals/LoginModal";
import { IoIosClose } from "react-icons/io";
import { getParentCategory } from "../APIS/apis";
import { RiArrowDropDownLine } from "react-icons/ri";
import SidebarNavbar from "../Layouts/SidebarNavbar";
import { RxCross1 } from "react-icons/rx";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2e2e97",
    },
  },
});

function Navbar({
  getUserAuthenticationRedux,
  setUserAuthenticationRedux,
  getCartLengthRedux,
  getUserDetailRedux,
  getWishLengthRedux,
  getSelectedLanguage,
}) {
  const { t } = useTranslation();
  let Relove_Token = localStorage.getItem("Relove_Token");
  const searchRef = useRef();
  const routeName = window.location.pathname.split("/")[1];
  const navigation = useNavigate();
  const [searchInput, setSearchInput] = useState([]);
  const [inputValue, setinputvalue] = useState("");
  let language = localStorage.getItem("language");

  const [data, setData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [show, setShow] = useState(false);
  const [catshow, setCatshow] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false)


  useEffect(() => {
    setUserAuthenticationRedux(
      getUserAuthenticationRedux ? getUserAuthenticationRedux : Relove_Token
    );
  }, [getCartLengthRedux]);

  const searchIteam = (searchvalue) => {
    setinputvalue(searchvalue);
    Searchproduacts(
      getSelectedLanguage,
      searchvalue,
      getUserDetailRedux?.id || "",
      (data) => {
        setSearchInput(data.data.products);
      }
    );
  };


  useEffect(() => {
    getParentCategory(language, (response) => {
      setData(response);
      setCatData(response);
    });
  }, [language]);

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <LoginModal
        setShowLoginModal={setShowLoginModal}
        showLoginModal={showLoginModal}
      />
      <div className="container-fluid px-lg-4 px-md-3">
        <div className="row d-md-flex d-none justify-content-between navbar-margin">
          <div className="col-xl-3 col-4 col-md-4 col-lg-2">
            <div className="navbar-logos">
              <img
                onClick={() => navigation("/")}
                src="/img/logo_main.png"
                alt="logo-img"
              />
            </div>
          </div>

          <div className="col-xl-4 col-lg-5 d-lg-flex align-items-center justify-content-end d-none">
            <div className="navbar-menu-items">
              <ul>
                <li className="li_one" onClick={() => navigation("/")}>
                  {t("HOME")}
                </li>
                {data.length > 0
                  ? data.map((item, i) => {
                    return (
                      <ul
                        onClick={() =>
                          navigation(`/category/${item.id}/${item.slug}`)
                        }
                        key={i}
                      >
                        {item.name.toUpperCase() === 'WOMEN' || item.name.toUpperCase() === 'MEN' ? <li className="li_one">{item.name.toUpperCase()}</li> : ''}
                      </ul>
                    );
                  })
                  : <>
                    <li className="li_one">MEN</li>
                    <li className="li_one">WOMEN</li>
                  </>}

                {
                  catData?.length >= 0 && <li
                    className="d-flex align-items-center catDropdown"
                    onMouseEnter={() => setCatshow(true)}
                    onMouseLeave={() => setCatshow(false)}
                  >
                    {t("CATEGORIES")}{" "}
                    <RiArrowDropDownLine style={{ fontSize: "20px" }} />
                    {catshow ? (
                      <div className="catDropdown-content">
                        {catData.length > 0
                          ? catData.map((item, i) => {
                            return (
                              <React.Fragment key={i}>
                                {item.name.toUpperCase() !== 'WOMEN' && item.name.toUpperCase() !== 'MEN' ? <p
                                  className="catDropdownhover text-dark text-start m-0"
                                  onClick={() =>
                                    navigation(
                                      `/category/${item.id}/${item.slug}`
                                    )
                                  }
                                >
                                  {item.name.toUpperCase()}
                                </p> : ''}
                                {i < catData.length - 1 && (
                                  <hr className="m-0" style={{ color: 'grey', bottomWidth: "2px" }} />
                                )}
                              </React.Fragment>
                            );
                          })
                          : ""}
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                }
              </ul>
            </div>
          </div>

          <div className="col-xl-3 col-md-5 col-lg-3  d-md-flex d-none align-items-center navbar-search-section">
            <div
              className="navbar-search-field d-flex align-items-center"
              style={{ position: "relative" }}
              ref={searchRef}
            >
              <FiSearch />
              <input
                type="text"
                name=""
                id=""
                value={inputValue}
                onChange={(e) => searchIteam(e.target.value)}
                placeholder={t("searchforproduct")}
              />
              {inputValue.length > 0 ? (
                <div className="search-bar-result">
                  {inputValue.length > 0
                    ? searchInput?.map((cur, i) => {
                      return (
                        <div key={i} className="text-center">
                          <p
                            className="bg-white"
                            style={{ width: "100%", cursor: "pointer" }}
                            onClick={() => {
                              navigation(`/product/${cur.id}`);
                            }}
                          >
                            {cur.name}
                          </p>
                        </div>
                      );
                    })
                    : ""}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className="col-md-1 d-flex d-lg-none align-items-center justify-content-end navbar-toggle-section"
            style={{ position: "relative" }}
          >
            <IoMenu
              style={{ fontSize: "40px", cursor: "pointer" }}
              onClick={() => setShow(true)}
            />
          </div>

          {show ? (
            <SidebarNavbar show={show} handleClose={handleClose} data={data} userId={getUserDetailRedux?.id} setShowLoginModal={setShowLoginModal} getWishLengthRedux={getWishLengthRedux} getCartLengthRedux={getCartLengthRedux} />
          ) : (
            ""
          )}

          <div className="col-xxl-1 col-md-2 d-lg-flex align-items-center justify-content-end d-none">
            <div className="navbar-last-items">
              {Relove_Token ? (
                <div
                  className="navbar-last-item-2"
                  onClick={() => navigation("/profile")}
                >
                  <SlUser />
                </div>
              ) : (
                <div
                  className="navbar-last-item-2"
                  onClick={() => navigation("/login")}
                >
                  <SlUser />
                </div>
              )}

              <div
                className="navbar-last-item-2"
                onClick={() => {
                  let Relove_Token = localStorage.getItem("Relove_Token");
                  if (!Relove_Token) {
                    setShowLoginModal(true);
                  } else {
                    navigation("/wishlist");
                  }
                }}
                style={{ paddingLeft: "25px", paddingRight: "25px" }}
              >
                <ThemeProvider theme={theme}>
                  <Badge
                    theme={theme}
                    badgeContent={getWishLengthRedux}
                    color="primary"
                  >
                    <BsHeart />
                  </Badge>
                </ThemeProvider>
              </div>
              <div
                onClick={() => {
                  let Relove_Token = localStorage.getItem("Relove_Token");

                  if (!Relove_Token) {
                    setShowLoginModal(true);
                  } else {
                    navigation("/cart");
                  }
                }}
                className="navbar-last-item-2"
              >
                <ThemeProvider theme={theme}>
                  <Badge
                    theme={theme}
                    badgeContent={getCartLengthRedux}
                    color="primary"
                  >
                    <BsBagCheck />
                  </Badge>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-md-none d-flex">
          <div className="col-2 d-flex align-items-center justify-content-start">
            <IoMenu
              style={{ fontSize: "40px", cursor: "pointer" }}
              onClick={() => setShow(true)}
            />
          </div>
          <div className="col-8 d-flex align-items-center justify-content-center">
            <img
              onClick={() => navigation("/")}
              src="/img/logo2.jpg"
              alt="logo-img"
            />
          </div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            {
              !showNavbar ? <FiSearch
                style={{ fontSize: "25px", cursor: "pointer" }}
                onClick={() => setShowNavbar(!showNavbar)}
              /> : <RxCross1
                style={{ fontSize: "25px", cursor: "pointer" }}
                onClick={() => setShowNavbar(!showNavbar)}
              />
            }
          </div>
        </div>
        {
          showNavbar && <div className="col-12 d-flex d-md-none align-items-center navbar-search-section pb-3">
            <div
              className="navbar-search-field d-flex align-items-center"
              style={{ position: "relative" }}
              ref={searchRef}
            >
              <FiSearch />
              <input
                type="text"
                name=""
                id=""
                value={inputValue}
                onChange={(e) => searchIteam(e.target.value)}
                placeholder={t("searchforproduct")}
              />
              {inputValue.length > 0 ? (
                <div className="search-bar-result">
                  {inputValue.length > 0
                    ? searchInput?.map((cur, i) => {
                      return (
                        <div key={i} className="text-center">
                          <p
                            className="bg-white"
                            style={{ width: "100%", cursor: "pointer" }}
                            onClick={() => {
                              navigation(`/product/${cur.id}`);
                            }}
                          >
                            {cur.name}
                          </p>
                        </div>
                      );
                    })
                    : ""}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        }
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
    getCartLengthRedux: state.length.length,
    getWishLengthRedux: state.wishlength.length,
    getUserDetailRedux: state.user.data,
    getSelectedLanguage: state.language.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserAuthenticationRedux: (parameter) => {
      dispatch(setUserAuthentication(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
