import React, { useEffect, useState } from 'react';
import Topbar from '../Sections/Topbar'
import Footer from '../Sections/Footer';
import Navbar from '../Sections/Navbar';


const PrivacyPolicy = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])
  return (
    <>
      <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
        <Topbar />
        <Navbar />
      </div>
      <div className="container py-3 privacy-policy">
        <h3 className='fw-bold d-flex justify-content-center mb-5'>Privacy Policy</h3>
        <p>
          <b>
            This Privacy Policy (“Policy “) is published in accordance with the
            provisions of Rule 3(1) of the Information Technology (Intermediaries
            Guidelines) Rules, 2011. The Policy is an ‘electronic record’ as
            contemplated under section 2(t) of the Information Technology Act, 2000
            and the rules. This Policy being an electronic record is generated by a
            computer system and does not require any physical or digital signature.
          </b>
        </p>
        <h6 className="mb-3">
          <b>INTRODUCTION</b>
        </h6>
        <p className="py-1">
          The online store www.hjhasasi.com ("Online Store") is owned and operated by
          HASASI DENIM WEAR (hereafter referred to as “HDW Shopping”) under the laws
          of India having its registered office at Unit Number: 914, 9th Floor, Shiv
          Solitaire, Near Railway Station, Jogeshwari East Mumbai - 400060.
        </p>
        <p className="py-1">
          Users are requested to read and understand the Policy very carefully before
          using or accessing the Online Store. Through this Policy, HDW Shopping
          intends to convey the manner in which the user’s information is collated and
          used by HDW Shopping while providing the services of the Online Store. This
          Policy is binding on every user of the Online Store and users who do not
          agree with the terms of this Policy should not use or access the Online
          Store.
        </p>
        <p className="py-1">
          HDW Shopping reserves its right to change, edit, alter, amend, modify,
          review, revise or substitute this Policy with or without notice. By using
          the Online Store, the users signify they have carefully read, understood,
          and agree to be bound by this Policy, and the users expressly and
          voluntarily accept this Policy including any change, modification, or
          substitution made to the Policy from time to time. HDW Shopping reserves its
          right to refuse services to any user who does not accept or violates the
          terms of this Policy.
        </p>
        <h6 className="mb-3">
          <b>USER INFORMATION </b>
        </h6>
        <p className="py-1">
          ‘User information’ for the purpose of this Policy includes the user’s
          personal information that can be used to uniquely identify or contact a
          single person including name, email address, residential address, phone
          number, photograph and other information collected by HDW Shopping to
          provide specific services to the user through the Online Store. The user has
          the option not to provide User Information if the user wishes not to avail
          the specific service for which the User Information is required.
        </p>
        <p className="py-1">
          If any user is providing any User Information on behalf of any corporation,
          company, institution, association or other entity, the user should be
          authorized by such corporation, company, institution, association or other
          entity to provide such information to HDW Shopping.
        </p>
        <p className="py-1">
          HDW Shopping collects information from the user when creating a user account
          or when the user is browsing for products, transacting an online purchase of
          products through the Online Store or while posting comments, uploading
          content or participating in any offers or contests or availing special gifts
          or communicating with customer support by phone, email or otherwise.
        </p>
        <p className="py-1">
          HDW Shopping automatically receives the URL of the site from which a user
          came and the site to which the user goes when the user leaves the Website.
          The Online Store receives the IP address of the user’s computer and the type
          of web browser a user is using, email patterns, etc. HDW Shopping will
          occasionally ask the user to complete optional online surveys. These surveys
          may ask the user for contact information and demographic information (e.g.
          zip code, age, or income level).
        </p>
        <p className="py-1">
          Sometimes the Online Store uses cookies so that the Online Store can provide
          the user with more customized information when the user returns to the
          Online Store. "Cookies" are used to store user preferences and to track user
          trends, so as to enhance the user’s interactive experience and generally
          improve the Online Store’s services to the user. The user can set the
          browser to notify the user when the user is sent a "cookie", giving the user
          the chance to decide whether or not to accept it. If the user accepts a
          "cookie", the user agrees to the use of any User Information collected by
          the Online Store using that cookie. The user may update the profile
          information of the user at any time on the Online Store.
        </p>
        <p className="my-3">
          The user can add or update the User Information on a regular basis. When the
          user updates the User Information, HDW Shopping will keep a copy of the
          prior version for its records.
        </p>
        <h6 className="mb-3">
          <b>USE OF INFORMATION &amp; DISCLOSURE</b>
        </h6>
        <p className="py-1">
          The User Information is primarily used by HDW Shopping to facilitate a
          better, customized and convenient use of the Online Store’s services. The
          User Information is used by HDW Shopping inter alia to (i) respond to the
          user’s queries, requests and inquiries; (ii) update new features of the
          Online Store, (iii) improve and customize the Online Store’s service, (iv)
          detect and prevent fraud and abuse of the Online Store, (v) examine the
          identity of the user’s and the authenticity of offers, delivery or transfer
          of offers, etc. (vi) administer the Online Store, (vii) inform any change in
          the Terms of Use or this Policy or the Terms and Conditions of Sale, (viii)
          send surveys and marketing communications that HDW Shopping believes may be
          of interest to the user; (ix) personalize the user’s experience on the
          Online Store by presenting advertising, products and offers tailored for the
          user; (x) help the user address its problems with the Online Store including
          addressing any technical problems; (xi) complete and fulfil the user’s
          purchase, process payments, communicate with the user regarding the user’s
          purchase and provide the user with related customer service; and (xii)
          protect the integrity of the Online Store.
        </p>
        <p className="py-1">
          User Information is used and disclosed if required to do so by law or in the
          good faith and belief that such disclosure is reasonably necessary to
          respond to notices, court orders, or other legal process. HDW Shopping may
          disclose User Information to law enforcement officers, third party rights
          owners, or others that is in its opinion reasonably necessary to enforce the
          Online Store’s Terms of Use, this Policy and Terms and Conditions of Sale.
          HDW Shopping will share some or all of the User Information with another
          entity should HDW Shopping merge with, or be acquired by that entity or
          receive investments from that entity.
        </p>
        <p className="py-1">
          HDW Shopping may disclose aggregated information about the use of the
          Website, but it will never contain User Information that will identify a
          single user.
        </p>
        <p className="py-1">
          HDW Shopping does not trade or sell User Information in any manner, except
          as specified in this Policy or if express consent is sought from the user.
        </p>
        <p className="py-1">
          HDW Shopping understands that the User Information is very crucial and is
          important for the user. The user agrees and acknowledges that the User
          Information is disclosed or shared with: (i) third party vendors for
          delivery of the products ordered through the Website, (ii) third party
          service providers engaged by
        </p>
        <p className="py-1">
          HDW Shopping to perform the services on HDW Shopping’s behalf, (iii) third
          party service providers for the purpose of the Website’s security,
          maintenance, repair or other technical reasons.
        </p>
        <p className="py-1">
          HDW Shopping may, if the user so chooses, send direct advertisement mailers
          to the user at the address given by the user. The user has the option to
          opt-out of this direct mailer by clicking at the unsubscribed link option
          attached to e-mail.
        </p>
        <p className="py-1">
          HDW Shopping respects the user’s privacy and if the user does not want to
          receive e-mail or other communications from HDW Shopping, user may inform
          the same to our customer care service.
        </p>
        <h6 className="mb-3">
          <b>SECURITY &amp; USER RESTRICTION</b>
        </h6>
        <p className="py-1">
          HDW Shopping implements standard measures to protect against unauthorized
          access to and unlawful interception of the User Information. However, no
          internet site can fully eliminate security risks.
        </p>
        <p className="py-1">
          HDW Shopping endeavours to take all measures to protect the security,
          integrity and confidentiality of the User Information against unauthorized
          breach and hacking. For the purpose of checking possible vulnerabilities and
          attacks.
        </p>
        <p className="py-1">
          HDW Shopping may conduct periodical internal review of data and security
          measures on the Website.
        </p>
        <p className="py-1">
          HDW Shopping may use such software as it determines is best for maintaining
          security. However, the internet is not absolutely a secure environment, and
          the Website cannot ensure or warrant a 100% security of the User
          Information.
        </p>
        <p className="py-1">
          If any user is provided with any identification code, password or any other
          piece of information as part of the Website’s security procedure, the user
          should treat such information as confidential, and the user should not
          disclose it to any third party including to other users. To prevent third
          party’s access to the Website the user should sign out from the user account
          after availing the services through the Website.
        </p>
        <p className="py-1">
          HDW Shopping has the right to disable the identification code or password,
          whether chosen by any user or allotted by HDW Shopping, at any time, if the
          user fails to comply with instructions or terms, conditions or policies of
          HDW Shopping.
        </p>
        <p className="py-1">
          The user is solely responsible for the activities that occur under the
          user’s account including the confidentiality of the user’s password and HDW
          Shopping is not responsible for the same. While using and accessing the
          Website, the user is responsible for maintaining the confidentiality of the
          user’s account and for restricting access to the user’s computer or other
          system or equipment’s through which the user uses and accesses the Website.
        </p>
        <p className="py-1">
          Not with standing anything contained in this Policy or elsewhere,
        </p>
        <p className="py-1">
          HDW Shopping shall not be held responsible for any loss, damage or misuse of
          the User Information, if such loss, damage or misuse is attributable to a
          Force Majeure Event. A "Force Majeure Event" means any event that is beyond
          the reasonable control of
        </p>
        <p className="py-1">
          HDW Shopping and includes, without limitation, fire, flood, explosion, acts
          of God, civil commotion, strikes, lock outs or industrial action of any
          kind, riots, insurrection, war, acts of government, power failure, sabotage,
          computer hacking, unauthorised access to computer data and storage device,
          system failure, virus, attacks, bugs, computer crashes, breach of security
          and encryption.
        </p>
        <h6 className="mb-3">
          <b>THIRD PARTY WEBSITES AND LINKS</b>
        </h6>
        <p className="py-1">
          Website may provide or be linked with other websites and advertisements. HDW
          Shopping is in no way responsible for the privacy practices or content of
          such websites. The linked websites or advertisers are not necessarily under
          the control of HDW Shopping and HDW Shopping expressly disclaims all
          liability associated with the content and the use of such websites and
          advertisements. Display of any third-party advertisements on the Website
          shall not be construed as an endorsement or promotion of any products or
          services by HDW Shopping. The user should read the privacy policy of each
          and every linked website and any access or use of the linked websites is
          entirely at the user’s own risk.
        </p>
        <p className="py-1">
          HDW Shopping is not responsible for any direct, indirect, solicited or
          unsolicited communication made by any of these linked third-party websites
          or advertisers or their respective agents or representatives, to the users
          including by telephone, email, mobile phones and facsimile.
        </p>
        <p className="py-1">
          These linked third-party websites and advertisers may use various
          technologies to receive the user’s IP address and personal information. The
          third-party advertisers may also use cookies, JavaScript, web beacons and
          other technologies to gauge the effectiveness of their advertisements and to
          personalize advertising experience and content.
        </p>
        <p className="py-1">
          HDW Shopping does not have any control over the cookies or technologies that
          advertisers or websites may use and the user should contact them directly
          for more information about their privacy practices and policies.
        </p>
        <p className="py-1">
          Based on the User Information including the nature of products purchased by
          the user through the Online Store, HDW Shopping may display personalized
          third-party advertisements on the Online Store. When the user clicks on the
          advertisement link, the third-party advertisers may assume that the user has
          met the criteria that was used to personalize the advertisement. The user
          acknowledges and agrees that in no event
        </p>
        <p className="py-1">
          HDW Shopping is responsible to the user accessing the services of
          third-party advertisers or other websites.
        </p>
        <h6 className="mb-3">
          <b>GENERAL TERMS</b>
        </h6>
        <p className="py-1">
          his Policy is to be read in conjunction with and shall be deemed to be part
          of the Online Store’s Terms of Use and Terms and Conditions of Sale.
        </p>
        <p className="py-3 ">
          The User Information resides in HDW Shopping’s database for a certain period
          of time even after the user ceases to use or access the Online Store and in
          such cases HDW Shopping is not be liable for any usage of the User
          Information after the user ceases to use or access the Online Store.
        </p>
        <p className="py-1">
          The Users agree and acknowledge that User Information collated through the
          Online Store could be stored and processed in any other country across the
          world. The users acknowledge that for effective provision of the Online
          Store’s services in respective territories including territories outside
          India, the User Information may be stored or processed by trusted third
          parties. Whenever the User Information is shared with trusted third parties
          in countries outside India, HDW Shopping endeavour’s that the User
          Information is transferred in accordance with the applicable laws of India.
        </p>
        <p className="py-1">
          The user should be aware that the User Information or other data, the user
          provides to the Online Store for availing the services or transacting
          through the Online Store could be read, collected, or used by other users or
          third parties in which case the User Information might be used for sending
          unsolicited messages or communications for which HDW Shopping is not
          responsible.
        </p>
        <p className="py-1">
          The user agrees and acknowledges HDW Shopping’s right to utilize the user’s
          posts, contents, information and other personal data for the purpose of
          providing services through the Online Store. The contents on the Website may
          contain comments, post and reviews posted by the users which may be public
          and searchable on the internet in which case the user’s comments, post
          reviews and information and personal data provided to the Online Store may
          be viewed by other users and HDW Shopping cannot guarantee that other users
          or third parties have not made copies of or used the ideas and information
          that the user has shared on the Online Store.
        </p>
        <p className="py-1">
          PLEASE KINDLY MAKE SURE TO TAKE THE UNBOXING VIDEO WHENEVER THE ORDER GETS
          DELIVERED (It helps in case of any discrepancy like Item Lost or Duplicate
          Product Received or Wrong Product Received or Issue Related to Product).
          Refund in such cases is only possible if the Unboxing Video at the parcel
          delivered is available.
        </p>
        <h6 className="mb-3">
          <b>GRIEVANCE OFFICER</b>
        </h6>
        <p className="py-1 ">
          In case of any complaints or grievances, the user may contact the Grievance
          Officer as under:
          <br />
          <br />
          <b>
            Email Id: <a href="mailto:Support@hasasidenim.com" />
            support@hasasidenim.com
          </b>
        </p>
        <h6 className="mb-3">
          <b>GOVERNING LAW AND JURISDICTION</b>
        </h6>
        <p className="py-1">
          The terms and conditions of this Policy shall be governed by Indian Law and
          the courts in Mumbai shall only have exclusive jurisdiction to resolve any
          disputes.
        </p>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy