
import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";



export const AddReviewApi = (token, formdata) => {

  let data = JSON.stringify(

    formdata
  );


  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}add-review`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,

    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {

      SuccessToaster(response?.data?.message);
      //  console.log(response)


    })
    .catch((error) => {
      if (!token) {

      } else {
        ErrorToaster(error?.response?.data?.message);
      }

      console.log(error);
    });
};
export const FetchReviewApi = (formdata, callback) => {

  let data = JSON.stringify(
    formdata
  );


  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}product-reviews`,
    headers: {
      "Content-Type": "application/json",


    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {

      // SuccessToaster(response?.data?.message);
      callback(response)
      //  console.log(response)


    })
    .catch((error) => {
      // ErrorToaster(error?.response?.data?.message);

      // console.log(error);
    });
};

export const UploadImageReviewApi = (token, formdata, callback) => {




  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}image-uploads`,
    headers: {
      Authorization: `Bearer ${token}`,



    },
    data: formdata,
  };

  axios
    .request(config)
    .then((response) => {

      // SuccessToaster(response?.data?.message);
      callback(response)
      //  console.log(response)


    })
    .catch((error) => {
      // ErrorToaster(error?.response?.data?.message);

      // console.log(error);
    });
};



