import React, { useState } from "react";
import "./../Styles/bannermiddle.css";
import { IoMdPlay } from "react-icons/io";
import Videomodal from "../Modals/Videomodal";
import { useNavigate } from "react-router-dom";

function BannerMiddle({ videoBanner }) {
  const navigation = useNavigate()
  const [show, setShow] = useState(false)
  const video = videoBanner && videoBanner.length && videoBanner[0].video;

  const style1 = {
    backgroundImage: videoBanner && videoBanner.length > 0 && `url(${videoBanner[0]?.bannerImage})`,
  }

  return (
    <>
      <Videomodal show={show} setShow={setShow} video={video} />
      <div className="container-fluid bannermiddle_main p-0">
        <div className="row">
          <div className="col-xl-8 col-lg-7">
            <div className="left-section" style={style1}>
              <div className="c">
                <h5>{videoBanner && videoBanner.length > 0 && videoBanner[0]?.text_one}</h5>
                <h1>  {videoBanner && videoBanner.length > 0 && videoBanner[0]?.text_two}</h1>
                <p>
                  {videoBanner && videoBanner.length > 0 && videoBanner[0]?.text_three}
                </p>
                <button onClick={() =>
                  navigation(`/category/${videoBanner[0]?.categoryId}/${videoBanner[0]?.catSlug}`)
                }>Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5 mt-3 mt-lg-0">
            <div className="right_section" onClick={() => setShow(true)}>
              <img src="/img/banner_right.png" alt="" />
              <div className="videoplay">
                <IoMdPlay className="videoicon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerMiddle;
