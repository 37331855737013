import React from "react";
import "../Styles/Sale.css";
import { useNavigate } from "react-router-dom";

function Sale({ saleBanner }) {
  const navigation = useNavigate()
  const style1 = {
    backgroundImage: saleBanner && saleBanner.length > 0 && `url(${saleBanner[0]?.bannerImage})`,
  }
  const style2 = {
    backgroundImage: saleBanner && saleBanner.length > 1 && `url(${saleBanner[1]?.bannerImage})`,
  }

  return (
    <>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <div className="sale1" style={style1}>
              <div className="sale-content">
                <h3>{saleBanner && saleBanner.length > 0 && saleBanner[0]?.text_one}</h3>
                <h5 style={{ color: "#2e2e97", fontWeight: "bold" }}>
                  {saleBanner && saleBanner.length > 0 && saleBanner[0]?.text_two}
                  {/* Up to <span>30%</span> OFF */}
                </h5>
                <p>
                  {saleBanner && saleBanner.length > 0 && saleBanner[0]?.text_three}
                </p>
                <button onClick={() =>
                  navigation(`/category/${saleBanner[0]?.categoryId}/${saleBanner[0]?.catSlug}`)
                }>Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="sale1 sale2 mt-3 mt-md-0" style={style2}>
              <div className="sale-content">
                <h3>{saleBanner && saleBanner.length > 1 && saleBanner[1]?.text_one}</h3>
                <h5 style={{ color: "#2e2e97", fontWeight: "bold" }}>
                  {saleBanner && saleBanner.length > 1 && saleBanner[1]?.text_two}
                </h5>
                <p>
                  {saleBanner && saleBanner.length > 1 && saleBanner[1]?.text_three}
                </p>
                <button onClick={() =>
                  navigation(`/category/${saleBanner[1]?.categoryId}/${saleBanner[1]?.catSlug}`)
                }>Shop Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sale;
