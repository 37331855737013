import React, { useState } from "react";
import Code from "../Code";
import { connect } from "react-redux";
import { addAddressApi } from "../APIS/AuthApis";
import "./../Styles/profile.css";
import { ErrorToaster } from "../Toaster";
import { useTranslation } from "react-i18next";


function ProfileAddAddress({ getUserAuthenticationRedux }) {
  const [addressType, setAddressType] = useState('Home')
  const { t } = useTranslation();

  const [addressData, setAddressData] = useState({
    name: '',
    mobile: '',
    phone_code: "+91",
    phone_country: "in",
    street: '',
    landmark: '',
    state: '',
    city: '',
    code: '',
    address_type: addressType,
    default: 1
  })

  const handleAddAddress = (e) => {
    e.preventDefault();
    if (addressData?.code?.length !== 6) {
      ErrorToaster("Pincode should be of 6 digits")
    }
    else if (addressData?.mobile?.length !== 10) {
      ErrorToaster("Mobile Number should be of 10 digits")
    }
    else {
      addAddressApi(getUserAuthenticationRedux, addressData)
    }
  };

  const handleOnChange = (e) => {
    setAddressData({ ...addressData, [e.target.name]: e.target.value })
  }

  return (
    <>
      <form onSubmit={(e) => handleAddAddress(e)}>
        <div className="input-div-profile">
          <label htmlFor="Name">Full Name*</label>
          <input
            type="text"
            placeholder="Enter your full name"
            id="name"
            name="name"
            value={addressData?.name}
            onChange={handleOnChange}
            required
          />
        </div>

        <div className="profile-mobile-field">
          <h6 htmlFor="phone">Mobile No*</h6>
          <div className="row d-flex align-items-center">
            <div className="col-md-3">
            </div>
            <div className="input-div-profile col-md-12">
              <input
                type="number"
                placeholder="Enter your 10 digit mobile number"
                id="phone"
                name='mobile'
                value={addressData?.mobile}
                onChange={handleOnChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="input-div-profile">
          <label htmlFor="Landmark">Address (House No,Building,Street,Area)*</label>
          <input
            type="text"
            placeholder="Enter your full address"
            id="Landmark"
            name='landmark'
            value={addressData?.landmark}
            onChange={handleOnChange}
            required
          />
        </div>


        <div className="input-div-profile">
          <label htmlFor="Landmark">Locality/Town*</label>
          <input
            type="text"
            placeholder="Enter your full address"
            id="street"
            name='street'
            value={addressData?.street}
            onChange={handleOnChange}
            required
          />
        </div>

        <div className="d-flex m-0 p-0">
          <div className="input-div-profile w-50 m-0 p-0">
            <label htmlFor="State">State*</label>
            <input type="text" placeholder="State" id="State" name='state' value={addressData?.state} onChange={handleOnChange} required />
          </div>
          <div className="input-div-profile w-50 mx-1 m-0 p-0">
            <label htmlFor="City">City*</label>
            <input type="text" placeholder='City' id="State" name='city' value={addressData?.city} onChange={handleOnChange} required />
          </div>
        </div>
        <div className="input-div-profile">
          <label htmlFor="Code">Pin Code*</label>
          <input
            type="Number"
            placeholder="PinCode"
            id="Code"
            name='code'
            value={addressData?.code}
            onChange={handleOnChange}
            required
          />
        </div>

        <div className="profile-address-radio-buttons">
          <h6 htmlFor="Address">Address Type*</h6>
          <div className="d-flex ">
            <div className="form-check ">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onClick={(e) => { setAddressType('Home'); console.log(e) }}
                checked={addressType === 'Home' ? true : false}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Home
              </label>
            </div>
            <div className="form-check mx-5 ">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                onClick={(e) => { setAddressType('Office'); console.log(e) }}
                checked={addressType === 'Office' ? true : false}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Office
              </label>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="btn text-white d-flex justify-content-center align-items-center w-100 save-detalis"
        >
          Add Address
        </button>
      </form>
    </>
  );
}



const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAddAddress);


