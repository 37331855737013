import React, { useState, useEffect } from "react";
import Topbar from "../Sections/Topbar";
import Navbar from "../Sections/Navbar";
import ParentCategory from "../Sections/ParentCategory";
import Banner from "../Sections/Banner";
import FashionSale from "../Sections/FashionSale";
import AllProductHeading from "../Sections/AllProductHeading";
import AllProducts from "../Sections/AllProducts";
import BannerMiddle from "../Sections/BannerMiddle";
import TodayPopularSale from "../Sections/TodayPopularSale";
import Footer from "../Sections/Footer";
import { connect } from "react-redux";
import { getAllProductsHomeApi } from "../APIS/apis";
import {
  AddWishlistApi,
  FetchWishlistApi,
} from "../APIS/WishlistApis";
import { setAllProducts, setWishLength } from "../Redux/actions";
import IconCards from "../Sections/IconCards";
import Cards from "../Sections/Cards";
import SliclSlider from "../Sections/SliclSlider";
import Sale from "../Sections/Sale";
import Spinner from "react-bootstrap/Spinner";
import { getTestimonialApi } from "../APIS/apis";


function Home({
  setAllProductsRedux,
  getAllProductsRedux,
  getSelectedLanguage,
  getWishLengthRedux,
  setUpdateProductSetWishLength,
  getUserAuthenticationRedux,
  getUserDetailRedux
}) {
  const [selectItem, setSelectItem] = useState("All");
  const [allHeadings, setAllHeadings] = useState([]);
  const [updateProductPage, setUpdateProductPage] = useState(false);
  const [bannerImage, setBannerImage] = useState([])
  const [offerBanner, setOfferBanner] = useState([])
  const [saleBanner, setSaleBanner] = useState([])
  const [otherBanner, setOtherBanner] = useState([])
  const [videoBanner, setVideoBanner] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false);
  const [testimonials, setTestimonials] = useState([])
  const language = localStorage.getItem("language");


  useEffect(() => {
    fetchAllProducts();
    fetchTestimonial();
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  const fetchTestimonial = () => {
    getTestimonialApi((data) => {
      setTestimonials(data)
    })
  }

  console.log("hgetyeghk",getUserDetailRedux)
  const fetchAllProducts = () => {
    getAllProductsHomeApi(language, getUserDetailRedux?.id ,setLoading, (data) => {
      if (data && data.homeBanner) {
        setBannerImage(data.homeBanner[0]);
      }
      setOfferBanner(data?.offerBanner);
      setSaleBanner(data?.saleBanner)
      setOtherBanner(data?.otherBanner)
      setAllProductsRedux(data.homeProduct);
      setAllProducts(data.homeProduct)
      setAllHeadings(data.homeProduct);
      setVideoBanner(data.videoSection_arr)
    });
  };

  const handleClickHeader = (productID) => {
    if (productID === "All") {
      setSelectItem(productID);
      setAllProducts(getAllProductsRedux)
    } else {
      setSelectItem(productID);
      const item = getAllProductsRedux?.filter((product) => product?.id === productID);
      setAllProducts(item)
      // setAllProductsRedux(item);
      // getAllProductsHomeApi(language, (data) => {
      //   data?.homeProduct?.forEach((ele, i) => {
      //     if (ele.id === productID) {
      //       console.log("data", [ele]);
      //       setAllProductsRedux([ele]);
      //     }
      //   });
      // });
    }
  };

  const handleWisthlist = (productId) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
      productId: productId,
    };

    AddWishlistApi(
      Relove_Token,
      formdata,
      updateProductPage,
      setUpdateProductPage,
      FetchWishlistApi,
      setUpdateProductSetWishLength
    );
  };

  return (
    <>
      {
        loading ? <div className="product_page_loader">
          <Spinner animation="border" role="status">
          </Spinner>
        </div> : <>
          <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
            <Topbar />
            <Navbar />
          </div>
          <div className="home-desktop-banner">
            <Banner bannerImage={bannerImage.bannerImage} categoryId={bannerImage.categoryId} catSlug={bannerImage.catSlug} />
          </div>
          <div className="home-mobile-banner">
            <Banner bannerImage={bannerImage.mobileImage} categoryId={bannerImage.categoryId} catSlug={bannerImage.catSlug} />
          </div>
          <ParentCategory getSelectedLanguage={getSelectedLanguage} />
          <FashionSale offerBanner={offerBanner} />
          <AllProductHeading
            allProducts={allHeadings}
            handleClickHeader={handleClickHeader}
            selectItem={selectItem}
            setSelectItem={setSelectItem}
          />
          <AllProducts
            allProducts={allProducts}
            handleWisthlist={handleWisthlist}
            updateProductPage={updateProductPage}
          />
          <Sale saleBanner={saleBanner} />
          <BannerMiddle videoBanner={videoBanner} />
          {/* <TodayPopularSale data={data} /> */}
          <SliclSlider testimonials={testimonials}/>
          <IconCards />
          <Cards otherBanner={otherBanner} />
          <Footer />
        </>
      }


    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getAllProductsRedux: state.allProducts.products,
    getSelectedLanguage: state.language.language,
    getWishLengthRedux: state.wishlength.length,
    getUserAuthenticationRedux: state.userAuthentication.data,
    getUserDetailRedux: state.user.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAllProductsRedux: (parameter) => {
      dispatch(setAllProducts(parameter));
    },
    setUpdateProductSetWishLength: (parameter) => {
      dispatch(setWishLength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
