import React, { useState } from "react";
import Box from "@mui/material/Box";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { changePassword } from "../APIS/AuthApis";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #2e2e97",
  p: 4,
};

const Changepaswordmodel = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [input, setInput] = useState({
    oldPassword: "",
    newPassword: "",
    confirmnewPassword: "",
  });
  const handleinput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value });
  };
  const handleSubmit = (e) => {
    let Relove_Token = localStorage.getItem("Relove_Token");

    e.preventDefault();
    changePassword(Relove_Token, input, setOpen, navigation);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modalpopup" >
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="d-flex justify-content-between">
              <h5 style={{ marginBottom: "20px" }}>{t("ChangePassword")}</h5>
              <IoMdClose onClick={handleClose} fontSize={25} style={{ cursor: "pointer" }} />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label"> {t("OldPassword")}</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showPasswordOld ? "text" : "password"}
                    className="form-control"
                    name="oldPassword"
                    value={input.oldPassword}
                    onChange={handleinput}
                  />
                  <div
                    style={{ position: "absolute", top: "10px", right: "20px" }}
                  >
                    {showPasswordOld ? (
                      <AiFillEye
                        onClick={() => setShowPasswordOld(!showPasswordOld)}
                      />
                    ) : (
                      <AiFillEyeInvisible
                        onClick={() => setShowPasswordOld(!showPasswordOld)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label"> {t("NewPassword")}</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showPasswordNew ? "text" : "password"}
                    className="form-control"
                    name="newPassword"
                    value={input.newPassword}
                    onChange={handleinput}
                  />
                  <div
                    style={{ position: "absolute", top: "10px", right: "20px" }}
                  >
                    {showPasswordNew ? (
                      <AiFillEye
                        onClick={() => setShowPasswordNew(!showPasswordNew)}
                      />
                    ) : (
                      <AiFillEyeInvisible
                        onClick={() => setShowPasswordNew(!showPasswordNew)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label"> {t("ConfirmPassword")}</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    name="confirmnewPassword"
                    value={input.confirmnewPassword}
                    onChange={handleinput}
                  />
                  <div
                    style={{ position: "absolute", top: "10px", right: "20px" }}
                  >
                    {showPassword ? (
                      <AiFillEye
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <AiFillEyeInvisible
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-success"
                  type="submit"
                  style={{ backgroundColor: "#2e2e97", border: "none" }}
                >
                  {t("Update")}
                </button>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Changepaswordmodel;
