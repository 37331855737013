import React, { useEffect, useState } from "react";
import "../Styles/Review.css";
import { FetchReviewApi } from "../APIS/ReviewApi";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import ReviewmodelImage from "./ReviewmodelImage";
import { useTranslation } from "react-i18next";
import EditReview from "./EditReview";

const Review = ({ pid, getUserRedux, setAverageRating }) => {
  const [open7, setOpen7] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const [Id, setId] = useState(false);
  const [review, setReview] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const npage = Math.ceil(review.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const { t } = useTranslation();

  useEffect(() => {
    setId(getUserRedux);
    let userId = localStorage.getItem('uid')
    let fromdata = {
      page: currentPage,
      productId: pid,
      filter: "highRating",
      uid: userId || "",
    };
    if (Id) {
      fromdata.uid = Id.id;
    }

    FetchReviewApi(fromdata, (callback) => {
      setReview(callback.data.data);
      setAverageRating(callback.data?.data?.averageRating);
    });
  }, [currentPage, pid]);

  const perPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const changeCpage = (n) => {
    setCurrentPage(n);
    window.scroll(0, 0);
  };
  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const [modelImage, setModelImage] = useState([]);

  const ModelImagehandle = (cur) => {
    setModelImage(cur);
    setOpen7(true);
  };
  // console.log(review.averageRating)
  const averageRating = review.averageRating;
  // let reviewId={review.reviews.length>0?review.reviews[0].id:""}

  // const Edithandle=()=>{
  //   let reviewId=review.reviews[0].id
  //   setOpen1(true)
  //   console.log(reviewId)
  // }

  function lowercaseFirstLetter(str) {
    if (typeof str !== 'string' || str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <>
      {
        review?.length > 0 && <div className="Review my-2">
          <h5>{t("TopReviewheading")}</h5>

          {review.reviewsUser.map((curelem) => {
            return (
              <>
                <div className="mt-2" style={{ fontWeight: "bold" }}>
                  {lowercaseFirstLetter(curelem.createdBy?.name_en)}
                </div>
                <div className="d-flex align-items-center">
                  <Rating readonly initialValue={curelem.rating} size={15} />
                  <div className="ms-2" style={{ fontSize: "10px" }}>
                    {curelem.createdAt}
                  </div>
                </div>
                <div>{curelem.title}</div>
                <div style={{ color: "grey" }}>{curelem.message}</div>

                <div className="d-flex my-2">
                  {curelem.images.length > 0
                    ? curelem.images.map((curvalue) => {
                      return (
                        <div className="Review-image me-2">
                          <img src={curvalue} alt="" />
                        </div>
                      );
                    })
                    : ""}
                </div>
              </>
            );
          })}

          {/* <button className="btn btn-success" onClick={()=>setOpen1(true)}>Edit</button> */}
          <EditReview open1={open1} setOpen1={setOpen1} />

          {review.length > 0
            ? review.reviews.map((iteam) => {
              return (
                <div>
                  <div className="mt-3" style={{ fontWeight: "bold" }}>
                    {lowercaseFirstLetter(iteam.createdBy?.name_en)}
                  </div>
                  <div className="d-flex align-items-center">
                    <Rating readonly initialValue={iteam.rating} size={15} />
                    <div className="ms-2" style={{ fontSize: "10px" }}>
                      {iteam.createdAt}
                    </div>
                  </div>
                  <div>{iteam.title}</div>
                  <div style={{ color: "grey" }}>{iteam.message}</div>
                  <div className="d-flex my-2">
                    {iteam.images.length > 0
                      ? iteam.images.map((cur, i) => {
                        return (
                          <div className="Review-image me-2" key={i}>
                            <img
                              src={cur}
                              alt=""
                              onClick={() => ModelImagehandle(cur)}
                            />
                          </div>
                        );
                      })
                      : ""}
                  </div>
                </div>
              );
            })
            : ""}
        </div>
      }

      <ReviewmodelImage
        open7={open7}
        setOpen7={setOpen7}
        modelImage={modelImage}
      />

      {
        review.length > 10 ? <nav>
          <ul className="pagination d-flex justify-content-end">
            <li className="page-item">
              <NavLink
                to=""
                className={`page-link${currentPage === 1 ? "back" : ""}`}
                onClick={perPage}
              >
                {currentPage === 1 ? "" : <>{t("PrevPage")}</>}{" "}
              </NavLink>
            </li>
            {numbers.map((n, i) => (
              <li
                className={`page-item ${currentPage === n ? "active" : ""}`}
                key={i}
              >
                <NavLink
                  to=""
                  className="page-link"
                  onClick={() => changeCpage(n)}
                >
                  {n}
                </NavLink>
              </li>
            ))}
            <li className="page-item">
              <NavLink
                to=""
                className={`page-link${currentPage === numbers.length ? "next" : ""
                  }`}
                onClick={nextPage}
              >
                {currentPage === numbers.length ? "" : <>{t("NextPage")}</>}
              </NavLink>
            </li>
          </ul>
        </nav> : ""
      }
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserRedux: state.userid.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Review);
