import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../src/Styles/slider.css";
import { Rating } from "react-simple-star-rating";
import { IoMdStar } from "react-icons/io";
import { IoMdStarHalf } from "react-icons/io";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

function SliclSlider({ testimonials }) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <FaAngleRight
        className="FulfillmentSlider_Arrowr_right"
        onClick={onClick}
      />
    );
  };
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <FaAngleLeft
        className="FulfillmentSlider_Arrowr_left"
        onClick={onClick}
      />
    );
  };

  return (
    <>
      <div className="slick_slider py-5">
        <div className="container-fluid">
          <Slider
            {...settings}
            nextArrow={<CustomNextArrow />}
            prevArrow={<CustomPrevArrow />}
          >
            {
              testimonials && testimonials?.map((test, i) => (
                <div className="testimonial_main" key={i}>
                  <p>
                    {test?.comment}
                  </p>
                  <div className="testimonial_rating">
                    <Rating initialValue={test.rating} size={30} readonly />
                  </div>
                  <h5>{test?.name_en}</h5>
                  <h6>{test?.designation}</h6>
                </div>
              ))
            }

          </Slider>
        </div>
      </div>
    </>
  );
}

export default SliclSlider;
