import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";

export const CartloginApi = (
  email,
  password,
  setShowLoginModal,
  setUserAuthenticationRedux
) => {
  let data = JSON.stringify({
    email: email,
    password: password,
    device_token: "d",
    device_id: "d",
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if (response.data.status === "falied") {
        SuccessToaster(response.data.message);
      } else {
        SuccessToaster(response.data.message);
        localStorage.setItem("Relove_Token", response.data.data.token);
        setUserAuthenticationRedux(
          response.data.data.token?.replaceAll('"', "")
        );
        setShowLoginModal(false);
      }
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      ErrorToaster(error?.response?.data?.message);
      // if (err.length > 0) {
      //   ErrorToaster(error?.response?.data?.message[0]);
      // } else {
      
      // }
    });
};

export const SingleProductVariationChildApi = (token, formdata, callback) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}single-product-variation-child`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data.data);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};

export const SingleProductVariationApi = (token, fromdata, callback) => {
  let data = JSON.stringify(fromdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}single-product-variation`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if (response.data.data.variation.length === 0) {
        ErrorToaster("No Product Available");
      } else {
        callback(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const AddToCartApi = (
  token,
  fromdata,
  updateProductPage,
  setUpdateProductPage
) => {
  // setUpdateProductPage(!updateProductPage);
  let data = JSON.stringify(fromdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}add-to-cart`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if (response.data.status === "failed") {
        ErrorToaster(response.data.message);
      } else {
        SuccessToaster(response.data.message);
        setUpdateProductPage(!updateProductPage);
      }
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};

export const FetchCartApi = (token, formdata, thirdParam, callback) => {
  let data = JSON.stringify(formdata);
  let setLoading =
    typeof thirdParam === "function" && typeof callback === "function"
      ? thirdParam
      : null;
  let finalCallback =
    typeof thirdParam === "function" && typeof callback === "function"
      ? callback
      : thirdParam;

  if (setLoading) setLoading(true);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-carts`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      finalCallback(response.data);
      if (setLoading) setLoading(false);
    })
    .catch((error) => {
      if (setLoading) setLoading(false);
    });
};

export const UpdateCartApi = (
  token,
  formdata,
  updateCartPage,
  setUpdateCartPage
) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update-to-cart`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(response.data);
      if (response.data.status === "failed") {
        ErrorToaster(response.data.message);
      } else {
        setUpdateCartPage(!updateCartPage);
        SuccessToaster(response.data.message);
      }
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};

export const deleteCartApi = (
  token,
  formdata,
  updateCartPage,
  setUpdateCartPage
) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}remove-to-cart`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if (response.data.status === "failed") {
        ErrorToaster(response.data.message);
      } else {
        setUpdateCartPage(!updateCartPage);
        SuccessToaster(response.data.message);
      }
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};
