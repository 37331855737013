import React, { useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { FiLinkedin } from "react-icons/fi";
import "./../Styles/footer.css";
import { useNavigate } from "react-router-dom";
import { subscribeApi } from "../APIS/apis";
import { ErrorToaster } from "../Toaster";

function Footer() {
  const navigation = useNavigate();
  const [email ,setEmail] = useState('')

  const handleSubmit = ()=>{
    if(email===''){
      ErrorToaster('Field Required*')
    } else{
      subscribeApi(email)
    }
  }

  return (
    <>
      <div className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <div className="footer-first-section">
                <img src="\img\footer_logo.png" alt="" />
                <p>
                  Hasasi is a globally renowned brand known for its high-quality
                  denim clothing and accessories. In India, the brand offers a
                  wide range of clothing for men, women.
                </p>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 company_info">
              <div className="footer-first-section">
                <ul>
                  <li>Company Info</li>
                  <li
                    onClick={() => {
                      navigation("/about-us");
                      window.scroll(0, 0);
                    }}
                  >
                    About Company
                  </li>
                  <li
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Social Responsibility
                  </li>

                  <li
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Afiliate
                  </li>
                  <li
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Fashion Blogger
                  </li>
                  <li
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    {" "}
                    Careers
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6">
              <div className="footer-first-section">
                <ul>
                  <li>Customer</li>
                  <li
                    onClick={() => {
                      navigation("/privacy-policy");
                      window.scroll(0, 0);
                    }}
                  >
                    Privacy Policy
                  </li>
                  <li>How To Track</li>
                  <li>Size Guide</li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6">
              <div className="footer-first-section">
                <ul>
                  <li>Customer Care</li>
                  <li
                    onClick={() => {
                      navigation("/contact");
                      window.scroll(0, 0);
                    }}
                  >
                    Contact Us
                  </li>
                  <li
                    onClick={() => {
                      navigation("/terms-and-condition");
                      window.scroll(0, 0);
                    }}
                  >
                    Terms And Conditions
                  </li>
                  <li>Bonus Point</li>

                  <li>Recall Notices</li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="footer-second-section">
                <h3>Sign Up To Our Newsletter</h3>
                <section class="newsletter">
                  <div className="container-md">
                    <div className="row">
                      <div className="col-sm-12 p-0">

                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control subscribe-now-input"
                            placeholder="example@abc.com"
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                            required

                          />

                          <button className="btn" type="submit" onClick={handleSubmit} >
                            Subscribe Now
                          </button>

                        </div>

                      </div>
                    </div>
                  </div>
                </section>
                <p>
                  By clicking on the subscribe button you are agreeing to our
                  Privacy & Cookie Policies
                </p>
              </div>
            </div>
          </div>
          <div className="row social_row">
            <div className="col-md-3 social_main">
              <h3>CONNECT US</h3>
              <div className="footer-social-icon">
                <a href="https://www.instagram.com/hj_hasasi/" target="_blank"><FaInstagram /></a>
                <a href="https://www.facebook.com/HASASIDENIMOFFICIAL/" target="_blank"><FiFacebook /></a>
                <a href="https://in.linkedin.com/in/hasasi-denim-wear-912b11235?trk=public_profile_browsemap" target="_blank"><FiLinkedin /></a>
              </div>
            </div>
            <div className="col-md-6"></div>
            {/* <div className="col-md-3 payment_main">
              <h3>WE ACCEPT</h3>
              <div className="payment_icon">
                <img src="/img/american express.png" alt="" />
                <img src="/img/bitcoin.png" alt="" />
                <img src="/img/paypal.png" alt="" />
                <img src="/img/CB.png" alt="" />
                <img src="/img/Dmca.png" alt="" />
                <img src="/img/maestro.png" alt="" />
                <img src="/img/visa.png" alt="" />
                <img src="/img/mastercard.png" alt="" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container-fluid">
          <div className="row privacy_row">
            <div className="col-md-5 col-sm-8 copyrite">
              <p>© 2024 Copyright - HJ HASASI</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
