
import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyDaaFmAkWsirA_lXE_RTjikba0UXzkS7tg",
  authDomain: "Relove-1.firebaseapp.com",
  projectId: "Relove-1",
  storageBucket: "Relove-1.appspot.com",
  messagingSenderId: "138751178435",
  appId: "1:138751178435:web:5d615019a1f6a0b87c9476",
  measurementId: "G-VJQ6ED1TB9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth=getAuth(app);
const provider=new GoogleAuthProvider();
export {auth,provider};
