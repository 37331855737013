import { ALL_PRODUCTS } from "../type"


const initialState = {
    products: []
}

const allProductsReducers = (state = initialState, action) => {
    switch (action.type) {
        case ALL_PRODUCTS: return {
            ...state,
            products: action.payload
        }
        default: return state
    }
}

export default allProductsReducers;