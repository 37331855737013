
export const USER_AUTHENTICATION = 'USER_AUTHENTICATION'
export const LOGOUT = 'LOGOUT'
export const ALL_PRODUCTS = 'ALL_PRODUCTS'
export const SELECTED_LANGUAGE = "SELECTED_LANGUAGE"
export const CART_LENGTH = 'CART_LENGTH'
export const USER_DETAIL = 'USER_DETAIL'
export const Wish_LENGTH = "Wish_LENGTH"
export const Single_Address = "Single_Address"
export const User_Deatils = "User_Deatils";
export const Offer_Details = "Offer_Details"