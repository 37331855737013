import React from 'react'
// import Modal from "@mui/material/Modal";
import { IoIosClose } from "react-icons/io";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'

import Box from "@mui/material/Box";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function SizeChartModal({ openSizeChart, setOpenSizeChart }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "1px solid #e8d7b4",
    boxShadow: 20,
    p: 2,
    borderRaius: 5
  };


  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData(28, 28, 29, 44.5, 22.5),
    createData(30, 30, 31, 44.5, 23.1),
    createData(32, 32, 33, 44.5, 23.8),
    createData(34, 34, 35, 44.5, 24.3),
    createData(36, 36, 37, 44.5, 25),
    createData(38, 38, 39, 44.5, 25.6),
    createData(40, 40, 41, 44.5, 26.3),
    createData(42, 42, 43, 44.5, 26.9),
    createData(44, 44, 45, 44.5, 27.5),
  ];
  return (
    <>
      <Modal centered  size="xl" show={openSizeChart} onHide={() => setOpenSizeChart(false)}
         dialogClassName="modal-90w"
         aria-labelledby="example-custom-modal-styling-title"
         >
        <Modal.Header closeButton>
          <Modal.Title>Size Chart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='row d-flex justify-content-between flex-wrap' >
          <div className='col-12 col-md-8' >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Size</TableCell>
                    <TableCell align="right">Waist</TableCell>
                    <TableCell align="right">Inseam Length</TableCell>
                    <TableCell align="right">Length</TableCell>
                    <TableCell align="right">Hip</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.calories}</TableCell>
                      <TableCell align="right">{row.fat}</TableCell>
                      <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </div>
            <div className='col-12 col-md-4 p-2' >
              <h5>Men's Jeans</h5>
              <p>Not sure about your jeans size? Follow these simple steps to figure it out: Waist - Measure around the body at the natural waist line (where you wear your belt</p>
              <img src="/img/size_chart_1.png" alt="size-chart" />

              <h5 className='mt-4' >Women's Jeans</h5>
              <p> <b><i>Waist</i></b> - Measure around the body at the natural waistline (where you wear your belt). Length - Measure from the top of the waistband through the bottom hem of the garment's. Inseam Length - Measure from the crotch to the inside bottom of the leg. Rise - Measure from the crotch to the top of the waistband.</p>
              <img src="/img/size_chart_1.png" alt="size-chart" />

            </div>
          </div>
          </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenSizeChart(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default SizeChartModal
